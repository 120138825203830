import { render, staticRenderFns } from "./PleionV1_5.vue?vue&type=template&id=89979f66&scoped=true&"
import script from "./PleionV1_5.vue?vue&type=script&lang=js&"
export * from "./PleionV1_5.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "89979f66",
  null
  
)

export default component.exports