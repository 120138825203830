<template>
  <div class="py-2">
    <strong>Solar Eclipse</strong>
    <b-btn v-b-toggle="'moduleSolarEclipse'" variant="primary" class="collapse-btn-style" @pointerdown="collapseOpen = !collapseOpen"
           style="margin: 0px 10px 0px 20px;">{{ collapseIcon }}
    </b-btn>
    <b-collapse id="moduleSolarEclipse" class="mt-2">
      <b-card style="border: none;">
        <hr style="margin-top: 0.4rem;">

        <parameter param-key="p_277" :param-value="parameters['p_277']"></parameter>
        <div v-if="parameters['p_277'].value != 0">
          <parameter param-key="p_278" :param-value="parameters['p_278']"></parameter>
          <parameter param-key="p_279" :param-value="parameters['p_279']"></parameter>
          <parameter param-key="p_280" :param-value="parameters['p_280']"></parameter>
          <parameter param-key="p_281" :param-value="parameters['p_281']"></parameter>
          <h5 style="font-style: italic;">Impostazioni</h5>
          <div class="border-top mb-3"></div>
          <parameter param-key="p_147" :param-value="parameters['p_147']"></parameter>
          <parameter param-key="p_148" :param-value="parameters['p_148']"></parameter>
          <parameter param-key="p_149" :param-value="parameters['p_149']"></parameter>
          <parameter param-key="p_152" :param-value="parameters['p_152']"></parameter>
          <parameter param-key="p_251" :param-value="parameters['p_251']"></parameter>
          <parameter param-key="p_252" :param-value="parameters['p_252']"></parameter>

        </div>

      </b-card>
    </b-collapse>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import Parameter from "@/components/virtual-device/edit/parameter/v3/Parameter";

export default {
  name: "SolarEclipse",

  components: {
    Parameter
  },

  data() {
    return {
      collapseOpen: false
    }
  },

  computed: {
    ...mapGetters({
      getParameters: 'virtualDevice/parameters',
      getDeviceVariant: 'virtualDevice/deviceVariant'
    }),

    parameters: function () {
      return this.getParameters(this.$route.params.id);
    },

    collapseIcon: function () {
      return this.collapseOpen === false ? '+' : '-';
    },
  }
}
</script>

<style scoped>
.collapse-btn-style {
  padding: 0rem 0.4rem;
  margin: 0px 10px 0px 10px;
  min-width: 1.5rem; /*Needed because button gets narrower when icon is changed from '+' to '-'*/
}

.card-body {
  padding: 0;
}
</style>